<template>
	<div>
		<frame :top1="top1" :top2="top2">
			<div class="table">
				<div style="margin-left: -65%;margin-bottom: 10px;">物流>物流跟踪  {{orderSn}}</div>
			</div>
            <div><span style="font-weight:bold;font-size:25px;margin-right: 50%;color:red;">待取件</span> <span>运货单号：{{mailNo}}  |    快递公司：{{expTextName}}   |  快递客服：{{tel}}</span></div>
            <div class="block" style="display:flex;margin-left:15%" >
            <el-timeline>
               <el-timeline-item :timestamp="item.time" placement="top" v-for="item in dataArr">
                <el-card>
                    <!-- <h4>更新 Github 模板</h4> -->
                    <p>{{item.context}}</p>
                </el-card>
                </el-timeline-item>
            </el-timeline>
            </div>
            
		</frame>
	</div>
</template>

<script>
	import frame from "../public/Frame.vue";

	export default {
		data() {
			return {

				top1: "12-1",
				top2: ["12"],
				admin_type: "",
				xstoreid: '',
				storeList: [{}],
				storeid: "",
                orderSn:"",
                mailNo:"", //快递单号
                expTextName:"", //快递名称
                tel:"",
                dataArr:[],
			};
		},
		methods: {

			//修改上下架

			//刷新页面按钮
			refresh() {
				location.reload();
			},
		},

		created() {
            //接收页面值
            this.orderSn = this.$route.query.orderSn;
            console.log(this.$route.query.es);
			let login_type = this.$storage.getLocal('type')
			let token = this.$storage.getLocal('token');
            console.log(token);
            this.$request.expressSel({
            token,
            order_sn:this.orderSn,
            })
            .then((r) => {
                if (r.code == 0) {
                    console.log(r.data.showapi_res_body);
                    let data = r.data.showapi_res_body;
                    this.tel = data.tel;
                    this.mailNo = data.mailNo;
                    this.expTextName = data.expTextName;
                    this.dataArr = data.data;
                    // this.labelList = r.data.data;
                }
            });
		},
		watch: {},
		components: {
			frame,
		},
	};
</script>

<style scoped>
	.head {
		width: 100%;
		height: 45px;
		line-height: 45px;
		background-color: rgb(243, 243, 243);
	}

	.left,
	.right {
		display: inline;
	}

	.left {
		float: left;
		margin-left: 210px;
	}

	.left_tit {
		border-left: 5px solid red;
		font-size: 22px;
		padding: 5px;
	}

	.refresh {
		/* border:1px solid red ; */
		float: right;
		margin-right: 10%;
		margin-top: 2px;
	}

	.sele {
		display: flex;
		margin-top: 20px;
		margin-left: 200px;
	}

	.screen {
		border: 1px solid #ccc;
		margin-top: 15px;
		width: 85%;
		margin-left: 200px;
		height: 90px;
	}

	.screen_tit {
		height: 40px;
		background-color: rgb(243, 243, 243);
	}

	.screen_left {
		float: left;
		margin-left: 50px;
		margin-top: 10px;
	}

	.screen_right {
		float: right;
		margin-top: 6px;
		margin-right: 10px;
	}

	.screen_cont {
		margin-left: 20px;
		padding-top: 10px;
		font-size: 18px;
	}

	.transition-box {
		position: absolute;
		z-index: 2;
		top: 150px;
		left: 27%;
		width: 800px;
		height: 710px;

		box-shadow: 0px 0px 10px #888888;
		border-radius: 4px;
		background-color: #fff;
		text-align: center;
		color: #fff;
		padding: 40px 20px;
		box-sizing: border-box;
	}

	.act_tit {
		width: 800px;
		height: 50px;
		background-color: rgb(236, 128, 141);
		margin-top: -40px;
		margin-left: -20px;
		margin-bottom: 50px;
		font-size: 20px;
		line-height: 50px;
		display: flex;
		justify-content: space-between;
	}

	.table_head {
		height: 40px;
		margin-left: 200px;
		width: 85%;
		border: 1px solid #ccc;
		display: flex;
		justify-content: space-between;
		padding-top: 5px;
	}

	.table_head_left {
		margin-top: 5px;
		margin-left: 20px;
	}

	.table_head_right li {
		float: right;
		height: 30px;

		list-style: none;
		margin-right: 10px;
	}

	.block {
		margin-top: 30px;
	}

	.listcont {
		display: flex;
	}

	.shuai {
		display: flex;
		/* border:1px solid black */
	}

	.selname {
		width: 200px;
		border: 1px solid black;

	}

	.sel {
		/* margin-left: 10px; */
		margin-top: 5px;
	}
	.table
	{
	 background-color: rgb(245,247,249);
	 padding-top: 10px;
	 padding-bottom: 25px;
	}
	
	.bck
	{
		width: 85%;
		background-color: white;
		/* margin-top: 20px; */
		/* border: 1px solid red; */
		margin-left: 14%;
		/* margin-top: 20px; */
		padding-top: 20px;
		padding-bottom: 20px;
		/* line-height: 20px; */
	}
</style>
